<template>
  <v-container>
    <v-card class="py-md-5">
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <h4 v-if="!$route.params.id" class="py-5">Контактные данные</h4>
          <v-row v-if="!$route.params.id">
            <v-col class="py-0" cols="6">
              <v-text-field
                ref="email"
                dense
                label="Email:"
                v-model="defaultItem.email"
                :rules="emailRules"
              />
            </v-col>
            <v-col class="py-0" cols="6">
              <v-text-field
                ref="first_name"
                dense
                label="Имя:"
                v-model="defaultItem.first_name"
                :rules="[() => !!defaultItem.first_name]"
              />
            </v-col>
            <v-col class="py-0" cols="6">
              <v-text-field
                ref="last_name"
                dense
                label="Фамилия:"
                v-model="defaultItem.last_name"
                :rules="[() => !!defaultItem.last_name]"
              />
            </v-col>
            <v-col class="py-0" cols="6">
              <v-text-field
                ref="password"
                type="password"
                dense
                label="Пароль:"
                v-model="defaultItem.password"
                :rules="[() => !!defaultItem.password]"
              />
            </v-col>
          </v-row>
          <h4 class="py-5">Данные компании</h4>
          <v-row>
            <v-col class="py-0" cols="6">
              <v-text-field
                ref="name"
                dense
                label="Наименование"
                v-model="editedItem.company.name"
                :rules="[() => !!editedItem.company.name]"
              />
            </v-col>
            <v-col class="py-0" cols="6">
              <v-text-field
                ref="phone_number"
                dense
                label="Телефон"
                v-model="editedItem.company.phone_number"
                :rules="[() => !!editedItem.company.phone_number]"
              />
            </v-col>
            <v-col class="py-0" cols="6">
              <v-text-field
                ref="contact_person"
                dense
                label="Менеджер"
                v-model="editedItem.company.contact_person"
                :rules="[() => !!editedItem.company.contact_person]"
              />
            </v-col>
            <v-col class="py-0" cols="6">
              <v-text-field
                ref="companyemail"
                dense
                label="Email"
                v-model="editedItem.company.email"
                :rules="emailRules"
              />
            </v-col>
            <v-col class="py-0" cols="6">
              <v-text-field
                ref="_bin"
                dense
                label="БИН"
                v-model="editedItem.company._bin"
                :rules="[() => !!editedItem.company._bin]"
              />
            </v-col>
            <v-col class="py-0" cols="6">
              <v-text-field
                ref="_trn"
                dense
                label="Юридическое название"
                v-model="editedItem.company._trn"
                :rules="[() => !!editedItem.company._trn]"
              />
            </v-col>
            <v-col class="py-0" cols="6">
              <v-text-field
                ref="_iban"
                dense
                label="IBAN"
                v-model="editedItem.company._iban"
                :rules="[() => !!editedItem.company._iban]"
              />
            </v-col>
            <v-col class="py-0" cols="6">
              <v-text-field
                ref="_bik"
                dense
                label="БИК"
                v-model="editedItem.company._bik"
                :rules="[() => !!editedItem.company._bik]"
              />
            </v-col>
            <v-col class="py-0" cols="6">
              <v-text-field
                ref="_bank"
                dense
                label="Банк"
                v-model="editedItem.company._bank"
                :rules="[() => !!editedItem.company._bank]"
              />
            </v-col>
            <v-col class="py-0" cols="6">
              <v-text-field
                dense
                label="Долгота"
                type="number"
                v-model="editedItem.company.longitude"
              />
            </v-col>
            <v-col class="py-0" cols="6">
              <v-text-field
                dense
                label="Широта"
                type="number"
                v-model="editedItem.company.latitude"
              />
            </v-col>
            <v-col class="py-0" cols="6">
              <v-text-field
                dense
                label="Slug"
                v-model="editedItem.company.slug"
              />
            </v-col>
            <v-col class="py-0" cols="6">
              <v-select
                :items="[
                  { value: 'full', text: 'Фиксированное время заезда' },
                  { value: 'half', text: 'Нефиксированное время заезда' },
                  { value: 'all', text: 'Гибрид' }
                ]"
                item-value="value"
                item-text="text"
                v-model="editedItem.company.booking_time"
                label="Тип времени заезда"
              ></v-select>
            </v-col>
            <v-col class="py-0" cols="6">
                <v-text-field
                  v-model="editedItem.company.lcode"
                  dense
                  label="ID объекта в WuBook"
                />
            </v-col>
            <v-col class="py-0" cols="6">
              <v-text-field
                dense
                label="Время заезда"
                type="time"
								:ampm="false"
                v-model="time.check_in"
              />
            </v-col>
            <v-col class="py-0" cols="6">
              <v-text-field
                dense
                label="Время выезда"
                type="time"
								:ampm="false"
                v-model="time.check_out"
              />
            </v-col>
            <v-col class="py-0" cols="6">
              <v-checkbox
                class="mt-0"
                dense
                label="Трансфер"
                v-model="editedItem.company.has_transfer"
              />
            </v-col>
            <v-col class="py-0" cols="6">
              <v-checkbox
                class="mt-0"
                dense
                label="Рядом с морем"
                v-model="editedItem.company.near_coast"
              />
            </v-col>
            <v-col class="py-0" cols="6">
              <v-checkbox
                class="mt-0"
                dense
                label="Договор"
                v-model="editedItem.company.has_contract"
              />
            </v-col>
            <v-col class="py-0" cols="6">
              <v-select
                :items="company_service"
                v-model="editedItem.company.company_status"
                item-text="name"
                item-value="id"
                return-object
                label="Популярность"
              ></v-select>
            </v-col>
            <v-col class="py-0" cols="6">
              <v-select
                v-model="editedItem.company.feed"
                :items="company_food"
                item-text="name"
                item-value="id"
                label="Eда"
              ></v-select>
            </v-col>
            <v-col class="py-0" cols="6">
              <v-select
                v-model="editedItem.company.accommodation_type"
                :items="type_service"
                item-text="name"
                item-value="value"
                label="Тип размещения"
              ></v-select>
            </v-col>
            <v-col class="py-0" cols="6">
              <v-row align="center" class="mt-4">
                <p class="mb-0 mr-4 pl-4">Рейтинг</p>
                <v-rating
                  v-if="!$route.params.id"
                  v-model="editedItem.company.custom_rating"
                  background-color="warning"
                  color="warning"
                  length="5"
                ></v-rating>
                <v-rating
                  v-else
                  v-model="editedItem.company.custom_rating"
                  background-color="warning"
                  color="warning"
                  length="5"
                ></v-rating>
              </v-row>
            </v-col>
            <v-col class="py-0" cols="6">
              <v-select
                :items="places"
                v-model="selectedPlace"
                @change="getSubplaces"
                item-text="name"
                item-value="id"
                label="Местность"
                return-object
              ></v-select>
            </v-col>
            <v-col class="py-0" cols="6">
              <v-select
                v-model="editedItem.company.place"
                :items="sub_places"
                item-text="name"
                item-value="id"
                return-object
                label="Подместность"
              ></v-select>
            </v-col>
            <v-col class="py-0" cols="6">
              <v-textarea
                name="input-4-1"
                label="Meta description"
                v-model="editedItem.company.meta_description"
              ></v-textarea>
            </v-col>
            <v-col class="py-0" cols="6">
              <v-textarea
                name="input-4-1"
                label="Meta keywords"
                v-model="editedItem.company.meta_keywords"
              ></v-textarea>
            </v-col>
            <v-col class="py-0" cols="6">
              <v-textarea
                name="input-4-1"
                label="Meta title"
                v-model="editedItem.company.meta_tag"
              ></v-textarea>
            </v-col>
            <v-col class="py-0" cols="6">
              <v-text-field
                name="input-4-1"
                label="КБЕ"
                v-model="editedItem.company._kbe"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="6">
              <v-text-field
                name="input-4-1"
                label="КНП"
                v-model="editedItem.company._knp"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="6">
              <v-checkbox
                v-model="editedItem.company.nds"
                label="Плательщик НДС"
              ></v-checkbox>
            </v-col>
            <v-col class="py-0" cols="6">
              <v-checkbox
                v-model="editedItem.company.has_sftp"
                label="SFTP"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="close"> Отменить </v-btn>
        <v-btn
          color="primary"
          text
          @click="save"
          :disabled="disabledBtn"
          v-on:keydown.enter.prevent="save"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="error"
      :bottom="'bottom'"
      :color="'red'"
      :left="'left'"
      :timeout="6000"
    >
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="
            error = false;
            message = '';
          "
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { CompaniesApiUrls } from "@/services/accommodationRequests/companies.api.js";
import { LocationApiUrls } from "@/services/accommodationRequests/location.api.js";
import toast from "../../../plugins/toast";
export default {
  name: "ObjectInformation",
  components: {},
  data() {
    return {
      disabledBtn: false,
      reserve_type: null,
      reserve_time_list: [
        {
          text: "минуты",
          value: "minutes"
        },
        {
          text: "часы",
          value: "hours"
        },
        {
          text: "дни",
          value: "days"
        }
      ],
      places: [],
      sub_places: [],
      selectedPlace: null,
      company_food: [],
      company_service: [{ name: "Обычный", id: null }],
      type_service: [
        { name: "Зона отдыха", value: "rest_zone" },
        { name: "Гостиница", value: "hotel" },
        { name: "Гостевой дом", value: "guest_house" },
        { name: "Санаторий", value: "sanatorium" },
        { name: "Активный отдых", value: "leisure" },
        { name: "SPA", value: "spa" }
      ],
      emailRules: [
        v => !!v,
        v => /.+@.+\..+/.test(v) || "Введите правильный email"
      ],
      defaultItem: {
        rating: 0,
        email: "",
        first_name: "",
        last_name: "",
        password: "",
        application: "easybook"
      },
      editedItem: {
        company: {
          custom_rating: 0,
          name: "",
          phone_number: "",
          contact_person: "",
          email: "",
          _bin: "",
          _trn: "",
          _iban: "",
          _bik: "",
          _bank: "",
          check_in_time: "",
          check_out_time: "",
          latitude: null,
          longitude: null,
          payment_period: "",
          has_transfer: false,
          near_coast: false,
          feed: null,
          meta_description: null,
          meta_keywords: null,
          meta_tag: null,
          place: null,
          company_status: null,
          company_status_v2: null,
          company_service: "all_inclusive",
          accommodation_type: "",
          has_contract: false,
          reserve_time: null,
          time_limit_type: null,
          booking_time: "full",
          lcode: null,
          nds: false,
          has_sftp: false, 
        }
      },
      time: {
        check_in: "12:00",
        check_out: "12:00"
      },
      error: false,
      message: ""
    };
  },
  computed: {
    formTitle() {
      return !this.$route.params.id ? "Создать" : "Редактировать";
    }
  },
  async created() {
    this.company_food = await CompaniesApiUrls.getFeeds();
    const res = await CompaniesApiUrls.getStrips();
    res.forEach(element => {
      this.company_service.push(element);
    });
    this.places = await LocationApiUrls.getCities();
    if (this.$route.params.id) {
      let response = await CompaniesApiUrls.getCompany(this.$route.params.id);
      this.editedItem.company = response;
      if (
        this.editedItem.company.company_status_v2 &&
        !this.editedItem.company.company_status_v2.hasOwnProperty("id")
      ) {
        this.editedItem.company.company_status_v2 = {
          id: null,
          name: "Обычный"
        };
      }
      this.selectedPlace = response.city;
      this.time.check_in = response.check_in_time;
      this.time.check_out = response.check_out_time;
      this.sub_places = await LocationApiUrls.getPlaces({
        city_id: response.city
      });
    }
  },
  methods: {
    preventSigns(e) {
      if (e.key == "-" || e.key == "+") {
        e.preventDefault();
      }
    },
    async getSubplaces() {
      this.editedItem.company.place = null;
      this.sub_places = await LocationApiUrls.getPlaces({
        city_id: this.selectedPlace.id
      });
    },
    async save() {
      if (!this.$route.params.id) {
        if (!this.defaultItem.email) {
          toast.error("Напишите почту");
          return;
        }
        if (!this.defaultItem.first_name) {
          toast.error("Напишите имя");
          return;
        }
        if (!this.defaultItem.last_name) {
          toast.error("Напишите фамилию");
          return;
        }
        if (!this.defaultItem.password) {
          toast.error("Напишите пароль");
          return;
        }
      }
      if (!this.editedItem.company.booking_time) {
        toast.error("Выберите тип времени заезда");
        return;
      }
      if (!this.editedItem.company._kbe) {
        toast.error("Напишите КБЕ компании");
        return;
      }
      if (!this.editedItem.company._knp) {
        toast.error("Напишите КНП компании");
        return;
      }
      if (!this.editedItem.company.slug) {
        toast.error("Напишите slug компании");
        return;
      }
      if (!this.editedItem.company.name) {
        toast.error("Напишите имя компании");
        return;
      }
      if (!this.editedItem.company.phone_number) {
        toast.error("Напишите телефон компании");
        return;
      }
      if (!this.editedItem.company.contact_person) {
        toast.error("Напишите имя менеджера компании");
        return;
      }
      if (!this.editedItem.company.email) {
        toast.error("Напишите почту компании");
        return;
      }

      if (!this.editedItem.company._bin) {
        toast.error("Напишите БИН компании");
        return;
      }
      if (!this.editedItem.company._trn) {
        toast.error("Напишите юридическое название компании");
        return;
      }
      if (!this.editedItem.company._iban) {
        toast.error("Напишите IBAN компании");
        return;
      }
      if (!this.editedItem.company._bik) {
        toast.error("Напишите БИК компании");
        return;
      }
      if (!this.editedItem.company._bank) {
        toast.error("Напишите банк компании");
        return;
      }
      if (!this.editedItem.company.longitude) {
        toast.error("Напишите долготу компании");
        return;
      }
      if (!this.editedItem.company.latitude) {
        toast.error("Напишите широту компании");
        return;
      }
      if (!this.editedItem.company.feed) {
        toast.error("Выберите еду компании");
        return;
      }
      if (!this.editedItem.company.accommodation_type) {
        toast.error("Выберите тип размещения компании");
        return;
      }
      if (!this.editedItem.company.custom_rating) {
        toast.error("Выберите рейтинг компании");
        return;
      }
      if (
        this.editedItem.company.place === null ||
        this.editedItem.company.place === undefined
      ) {
        toast.error("Выберите подместность");
        return;
      }
      if (!this.editedItem.company.meta_description) {
        toast.error("Напишите meta описание");
        return;
      }
      if (!this.editedItem.company.meta_keywords) {
        toast.error("Напишите meta ключи");
        return;
      }
      if (!this.editedItem.company.meta_tag) {
        toast.error("Напишите meta тэги");
        return;
      }
      this.editedItem.company.check_in_time = this.time.check_in;
      this.editedItem.company.check_out_time = this.time.check_out;
      this.editedItem.company.time_limit_type = "days";
      this.editedItem.company.reserve_time = 1;

      delete this.editedItem.company.clients;
      delete this.editedItem.company.logo;
      this.defaultItem = { ...this.defaultItem, ...this.editedItem.company };
      this.defaultItem.admin = {
        email: this.defaultItem.email,
        first_name: this.defaultItem.first_name,
        last_name: this.defaultItem.last_name,
        password: this.defaultItem.password,
        user_role: "merchant_admin"
      };

      if (this.$route.params.id) {
        if (
          this.editedItem.company.company_status &&
          this.editedItem.company.company_status.hasOwnProperty("id")
        ) {
          this.editedItem.company.company_status = this.editedItem.company.company_status.id;
        } else {
          this.editedItem.company.company_status = null;
        }
        this.editedItem.company.place = this.editedItem.company.place.id;
        this.disabledBtn = true;
        if (this.editedItem.company.hasOwnProperty("private_key_fn")) {
          delete this.editedItem.company.private_key_fn;
        }
        if (this.editedItem.company.hasOwnProperty("public_key_fn")) {
          delete this.editedItem.company.public_key_fn;
        }

        try {
          CompaniesApiUrls.setCompany(
            this.$route.params.id,
            this.editedItem.company
          );
          this.disabledBtn = false;
          console.log({local_id: this.editedItem.company.id}, {storage_id: localStorage.getItem('company_id')});
          if(this.editedItem.company.id == localStorage.getItem('company_id')) {
            localStorage.setItem('booking_time', this.editedItem.company.booking_time)
          }
          this.close();
        } catch (e) {
          console.log(e);
          return;
        }
      } else {
        if (
          this.editedItem.company.company_status &&
          this.editedItem.company.company_status.hasOwnProperty("id")
        ) {
          this.editedItem.company.company_status = this.editedItem.company.company_status.id;
        } else {
          this.editedItem.company.company_status = null;
        }
        this.disabledBtn = true;
        this.defaultItem = { ...this.defaultItem, ...this.editedItem.company };
        this.defaultItem.place = this.defaultItem.place.id;
        try {
          CompaniesApiUrls.createCompany(this.defaultItem);
          this.disabledBtn = false;
          this.close();
        } catch (e) {
          console.log(e);
          return;
        }
      }
    },
    createCompany() {
      this.dialog = true;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = 0;
      });
    },
    close() {
      this.$store.commit("SET_TRIGGER_CHECK_NOTIFICATIONS_LOAD"); // обновить компании лист сверху справа
      this.$router.push("/companies");
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: orange !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
</style>
